import { Component, OnInit, ViewChild } from '@angular/core';
import { HEFService } from 'src/app/hefservice.service';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { GooglePlaceDirective } from 'ngx-google-places-autocomplete/ngx-google-places-autocomplete.directive';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-change-pass',
  templateUrl: './change-pass.component.html',
  styleUrls: ['./change-pass.component.scss']
})
export class ChangePassComponent implements OnInit {
  changePassForm: FormGroup;

  constructor(private hEFService: HEFService, private fb: FormBuilder, private router: Router, private toastr: ToastrService) { }

  ngOnInit(): void {
    this.changePassForm = this.fb.group({
      'newPass': [null, Validators.required],
      'confirmPass': [null, [Validators.required]]
    });
  }

  submitChangePass() {
    let formObj = this.changePassForm.value;
    let body = {}
    body['newpassword'] = formObj['newPass'];

    this.hEFService.setpassword(body).subscribe(res => {
      let success = res;
      if (success.status) {
        this.toastr.success(success.message);
        this.router.navigate(['/event'])
      }

    }, error => {
      let errors = error;
    });
  }
}

// function isValidConfirmPass(control: AbstractControl): ValidationErrors | null {
//   if (this.changePassForm?.get('newPass').value == control.value) {
//     return null;
//   } else {
//     return { "notMatch": true }
//   };
// }
