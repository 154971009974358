<div class="container">
  <h1 style="text-align: center;">Guest List</h1>
  <div class="card m-5 p-3" style="margin:1rem !important">
    <div class="row">
      <div class="col-lg-12">
        <div class="banner-content" style="padding-top: 0px;">
          <div class="banner-btn">
            <a style="height: 47px;margin: 0;margin-bottom: 15px;float: right;" routerLink="/addGuest"
              class="get-btn">Add New Guest</a>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12">
        <div class="table-responsive">


          <table style="margin-bottom: 0;" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger"
            class="table table-bordered table-striped table-hover">
            <thead>
              <tr>
                <th>ID</th>
                <th>First Name</th>
                <th>Last Name</th>
                <th>Email</th>
                <th>Ph. No.</th>

              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let volunteerEvent of volunteerEventList;let i = index">
                <td>{{i +1}}</td>
                <td>{{volunteerEvent.guest_fname }}</td>
                <td>{{volunteerEvent.guest_lname }}</td>

                <td>{{volunteerEvent.guest_email }}</td>
                <td>{{volunteerEvent.guest_phone }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>