<ngx-spinner type="ball-scale-multiple"></ngx-spinner>
<div class="container">
    <div class="row">
        <div class="col-lg-12 col-md-12">
            <div style="max-width: 100%;" class="contact-form">
                <div class="section-title text-center">
                    <h2>Create a New Event</h2>
                </div>

                <form [formGroup]="createEventForm" (submit)="submitEvent()">
                    <div class="row">
                        <div class="col-lg-4 col-sm-4">
                            <div class="form-group">
                                <label>Project Name</label>
                                <select  class="form-control" formControlName="project_id">
                                    <option [ngValue]="null" disabled>-- -- --Select Project-- -- --</option>
                                    <option *ngFor="let project of projectList" [ngValue]="project.id"> {{project.project_name}} </option>
                                  </select>
                            </div>
                        </div>

                        <div class="col-lg-4 col-sm-4">
                            <div class="form-group">
                                <label>Event Name</label>
                                <input type="text" name="name" formControlName="name"  id="name" class="form-control" 
                                    placeholder="Event Name" autocomplete="off">
                            </div>
                        </div>
                        <div class="col-lg-4 col-sm-4">
                            <div class="form-group">
                                <label>Event Date</label>
                                <input type="date" name="date" formControlName="date" id="date" class="form-control" 
                                    placeholder="Date">
                            </div>
                        </div>
                        <div class="col-lg-6 col-sm-6">
                            <div class="form-group">
                                <label>Event Start Time</label>
                                <input type="time" name="starttime" formControlName="starttime" value="07:30:00"  id="starttime" class="form-control" 
                                    placeholder="Start Time">
                            </div>
                        </div>

                        <div class="col-lg-6 col-sm-6">
                            <div class="form-group">
                                <label>Event End Time</label>
                                <input type="time" name="endtime" formControlName="endtime"  id="endtime" class="form-control" 
                                    placeholder="End Time">
                            </div>
                        </div>

                        

                        
                        <div class="col-lg-6 col-sm-6">
                            <div class="form-group">
                                <label>State</label>
                                <select formControlName="state_id" class="form-control">
                                    <option *ngFor="let state of stateList" [ngValue]="state.id"> {{state.state_name}} </option>
                                  </select>
                            </div>
                        </div>

                        <div class="col-lg-6 col-sm-6">
                            <div class="form-group">
                                <label>City</label>
                                <select  class="form-control" formControlName="city_id">
                                    <option *ngFor="let city of cityList" [ngValue]="city.id"> {{city.city_name}} </option>
                                  </select>
                            </div>
                        </div>

                        <div class="col-lg-6 col-sm-6">
                            <div class="form-group">
                                <label>Venue Name</label>
                                <input type="text" name="venueName" formControlName="venueName"  id="venueName" class="form-control" 
                                    placeholder="Venue Name" autocomplete="off">
                            </div>
                        </div>
                        <div class="col-lg-6 col-sm-6">
                            <div class="form-group">
                                <label>Venue Address (Pick Up From Google Provided Addresses)</label>
                                <input type="text" #placesRef="ngx-places" class="form-control" ngx-google-places-autocomplete (onAddressChange)="handleAddressChange($event)" formControlName="venue" placeholder="Venue Address*" >
                            </div>
                        </div>

                        
                        <div class="col-lg-6 col-sm-6">
                            <div class="form-group">
                                <label>Event Img / Flyer</label>
                                  <input type="file"  (change)="onFileSelect($event)" formControlName="event_img" name="image" id="image" class="form-control" 
                                  placeholder="Image">
                            </div>
                        </div>


                        <div class="col-lg-6 col-sm-6">
                            <div class="form-group">
                                <label>Event Description</label>
                                <textarea name="description" class="form-control" formControlName="desc" id="description" cols="12" rows="2"  placeholder="Description"></textarea>
                            </div>
                        </div>

                        <div class="col-lg-6 col-sm-6" style="margin-bottom: 14px;">
                            <div class="form-check form-check-inline">
                                <input class="form-check-input" value="online" id="flexRadioDefault1" (change)="onChangeRadio('online')"
                                    type="radio" name="onlineInPerson">
                                <label class="form-check-label" for="flexRadioDefault1">
                                    Online
                                </label>
                            </div>
                            <div class="form-check form-check-inline">
                                <input class="form-check-input" value="inperson"  id="flexRadioDefault1" (change)="onChangeRadio('inperson')" type="radio"
                                    name="onlineInPerson">
                                <label class="form-check-label" for="flexRadioDefault1"> 
                                    In Person
                                </label>
                            </div>
                        </div>

                        <div *ngIf="isOnline" class="col-lg-6 col-sm-6">
                            <div class="form-group">
                                <label>Zoom Link</label>
                                <input type="text" name="zoom_link" formControlName="zoom_link"  id="zoom_link" class="form-control" 
                                    placeholder="Zoom Link">
                            </div>
                        </div>

                        <div class="col-lg-12 col-md-12 text-center">
                            <button [disabled]="!createEventForm.valid" type="submit" class="btn default-btn">Submit</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>