import { Component, OnInit, ViewChild } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Subject } from 'rxjs';
import { HEFService } from 'src/app/hefservice.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { GooglePlaceDirective } from 'ngx-google-places-autocomplete/ngx-google-places-autocomplete.directive';

@Component({
  selector: 'app-events',
  templateUrl: './events.component.html',
  styleUrls: ['./events.component.scss']
})
export class EventsComponent implements OnInit {
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject<any>();
  volunteerEventList: any;
  @ViewChild("placesRef") placesRef: GooglePlaceDirective;

  persons: any[] = [];
  displayStyle = "none";
  displayStyleForGuest = "none";
  displayStyleForViewEvent = "none";
  editEventForm: FormGroup;
  guestForm: FormGroup;
  userDetails: any;
  cityList: any;
  stateList: any;
  projectList: any;
  imageName: any;
  getByIdEventObj: any;
  eventDetail: any;
  guestList: any;
  selectedGuestList: any;
  eventId: any;
  isOnline: boolean = false;
  event_type: number;

  constructor(private hEFService: HEFService, private router: Router, public toastr: ToastrService, private fb: FormBuilder) { }

  ngOnInit(): void {
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 2
    };
this.eventDetail={};
    this.editEventForm = this.fb.group({
      'name': [null, Validators.required],
      'date': [null, Validators.required],
      'desc': [null, Validators.required],
      'project_id': [null, Validators.required],
      'state_id': [null, Validators.required],
      'city_id': [null, Validators.required],
      'event_img': [null],
      'venue': [null, Validators.required],
      'starttime': [null, Validators.required],
      'endtime': [null, Validators.required],
      'venueName': [null],
      'lat': [null],
      'lng': [null],
      'zoom_link': [null],
    });
    this.guestForm = this.fb.group({

    })
    this.userDetails = JSON.parse(this.hEFService.getAuthUser());
    this.getAllData();
    // this.getAllState();
    // this.getAllProject();
    if (this.userDetails != null && this.userDetails != "") {
      this.editEventForm.get('state_id').setValue(this.userDetails.data.state_id);
      this.editEventForm.get('state_id').disable();
      this.getByIdCity(this.editEventForm.get('state_id').value);
    }


  }
  // getAllState() {

  // }
  // getAllProject() {

  // }
  openPopup(obj) {
    this.displayStyle = "block";

    this.hEFService.getEvent({ "event_id": obj.id }).subscribe(res => {
      this.getByIdEventObj = res;
      this.imageName = res.ev_detail_img;
      this.editEventForm.patchValue({
        'name': res.ev_detail_name,
        'date': res.ev_detail_date,
        'desc': res.ev_detail_desc,
        'project_id': res.ev_project_id,
        'state_id': res.ev_detail_stateid,
        'city_id': res.ev_detail_cityid,
        'venue': res.ev_detail_venue,
        'venueName': res.venue_name,
        'lat': res.latitude,
        'lng': res.longitude,
        'starttime': res.ev_detail_time,
        'endtime': res.ev_detail_etime,
        'zoom_link': res.zoom_link,
      });

      if (res.zoom_link != "null" && res.zoom_link != null && res.zoom_link != "") {
        this.isOnline = true;
        this.event_type = 1;
      } else {
        this.isOnline = false;
        this.event_type = 0;
      }

    }, error => {
      let errors = error;
    })

  }
  closePopup() {
    this.displayStyle = "none";
  }
  openGuestPopup(obj) {
    this.displayStyleForGuest = "block";
    this.eventId = obj.id;
    this.hEFService.getGuestListByEvent({ "id": obj.id }).subscribe(res => {
      this.guestList = res['guest_list'];
      this.selectedGuestList = [];
      //   for (let i = 0; i < this.guestList.length; i++) {
      //     let currentList = {};
      //     currentList['guest_email'] = this.guestList[i]['guest_email'];
      //     currentList['is_checked'] = this.guestList[i]['is_checked'];
      //     if (this.guestList[i]['is_checked'] == 0) {
      //       currentList['isSelected'] = false;
      //     }
      //     else {
      //       currentList['isSelected'] = true;
      //     }
      //     this.selectedGuestList.push(currentList);
      //   }
    });

  }
  closeGuestPopup() {
    this.displayStyleForGuest = "none";
  }
  closeViewPopup() {
    this.displayStyleForViewEvent = "none";
  }
  getAllData() {
    this.hEFService.getAllVolunteerEvent().subscribe(res => {
      this.hEFService.getAllStateData().subscribe(data => {
        this.hEFService.getAllProjectData().subscribe(projectData => {
          this.projectList = projectData;

          this.stateList = data;

          this.volunteerEventList = res;
        }, error => {
          let errors = error;
        })
      }, error => {
        let errors = error;
      });
    }
      , error => {
        let errors = error;
      });
  }
  displayViewEvent(obj) {
    this.displayStyleForViewEvent = "block";

    this.hEFService.getEvent({ "event_id": obj.id }).subscribe(res => {
      this.eventDetail = res;
    });
  }
  onCreateEvent() {
    this.router.navigate(['/createevent/']);
  }
  getGuestSelect(ev, index, data) {
    if (ev.target.checked == true) {

      this.selectedGuestList.push(data['guest_email']);
    }
    else {

      var filtered = this.selectedGuestList.filter(function (value, index, arr) {
        return value != data['guest_email'];
      });
      this.selectedGuestList = filtered;
    }
  }
  onDelete(obj) {
    this.hEFService.deleteEvent({ "id": obj.id }).subscribe(res => {
      let response = res;
      if (response.status) {
        this.toastr.success(response.message);
        this.ngOnInit();
      }
    }, error => {
      let errors = error;
    })
  }
  submitEvent() {

    let formObj = this.editEventForm.value;

    let body = new URLSearchParams();
    body.set('id', this.getByIdEventObj.id);
    body.set('name', formObj['name']);
    body.set('starttime', formObj['starttime']);
    body.set('endtime', formObj['endtime']);
    body.set('desc', formObj['desc']);
    body.set('event_img', this.editEventForm.get('event_img').value);
    body.set('date', formObj['date']);
    body.set('venue', formObj['venue']);
    body.set('vname', formObj['venueName']);
    body.set('project_id', formObj['project_id']);
    body.set('lat', formObj['lat']);
    body.set('lng', formObj['lng']);
    body.set('zoom_link', formObj['zoom_link']);
    body.set('event_type', this.event_type.toString());


    this.hEFService.updateEvent(body).subscribe(res => {
      let success = res;
      if (success.status) {
        this.toastr.success(success.message);
        this.closePopup();
        this.ngOnInit();
      }

    }, error => {
      let errors = error;
    });

  }
  onFileSelect(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.imageName = file.name;
      this.editEventForm.get('event_img').setValue(file);
    }
  }
  redirectInvited(event) {
    this.router.navigate(['/invitedGuest/' + event.id])
  }
  handleAddressChange(address: any) {
    this.editEventForm.get('venue').setValue(address.formatted_address);
    this.editEventForm.get('lat').setValue(address.geometry.location.lat());
    this.editEventForm.get('lng').setValue(address.geometry.location.lng());
  }
  getByIdCity(stateId) {
    if (stateId != null) {
      this.hEFService.getAllCityData(stateId).subscribe(res => {
        this.cityList = res;
        this.editEventForm.get('city_id').setValue(this.userDetails.data.city_id);
        this.editEventForm.get('city_id').disable();
      }, error => {
        let errors = error;
      });
    }
  }
  submitGuest() {
    let gustObj = {};
    gustObj['guestlist'] = this.selectedGuestList.toString();
    gustObj['eventid'] = this.eventId;
    gustObj['created_by'] = this.userDetails['data']['user_id'];
    this.hEFService.saveGuest(gustObj).subscribe(res => {
      
      let success = res;
      if (success.status) {
        this.toastr.success(success.message);
        this.displayStyleForGuest = "none";
      }

    }, error => {
      let errors = error;

    })
  }

  onChangeRadio(value) {
    if (value == "online") {
      this.isOnline = true;
      this.event_type = 1;
    } else {
      this.isOnline = false;
      this.event_type = 0;
      this.editEventForm.get('zoom_link').setValue(null);
    }
  }

  getUserSelect(ev, index) {
    // if(ev.target.checked == true) {
    //     this.userSelect.push(ev.target.value);
    // }
    // else {
    //     // removing any entry if user unchecked any previously selected value
    //     for(let i = 0;i < userSelect.length; i++) {
    //         if(userSelect[i] == ev.target.value) {
    //             this.userSelect.splice(i,1);
    //         }
    //     }
    // }
  }
}
