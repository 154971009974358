import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { HEFService } from 'src/app/hefservice.service';

type NewType = Subject<any>;

@Component({
  selector: 'app-guestlist',
  templateUrl: './guestlist.component.html',
  styleUrls: ['./guestlist.component.scss']
})
export class GuestlistComponent implements OnInit {
  dtOptions: DataTables.Settings = {};
  dtTrigger: NewType = new Subject<any>();
  volunteerEventList: any;

  persons: any[] = [];
  constructor(private hEFService: HEFService,private router: Router) { }

  ngOnInit() {
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 2
    };
    this.getAllVolunteerEvent();

  }

  getAllVolunteerEvent() {
    this.hEFService.getVolunteersGuests().subscribe(res => {
      this.volunteerEventList = res;
    }, error => {
      let errors = error;
    })
  }

  onCreateEvent(){
    this.router.navigate(['/createevent/']);
  }

}
