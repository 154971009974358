import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs-compat';
import { HEFService } from 'src/app/hefservice.service';

@Component({
  selector: 'app-invited-guests',
  templateUrl: './invited-guests.component.html',
  styleUrls: ['./invited-guests.component.scss']
})
export class InvitedGuestsComponent implements OnInit {
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject<any>();
  guestList: any;
  eventId:any;
  constructor(private hEFService: HEFService, private router: Router, public toastr: ToastrService, private fb: FormBuilder,private activeRoute: ActivatedRoute,) { }

  ngOnInit(): void {
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 2
    };
    let parms = this.activeRoute.snapshot.params;
    this.hEFService.getAllInvitedGuest({ "event_id": parms.parm1 }).subscribe(projectData => {
  
      this.guestList = projectData;
    });

  }
  onDelete(guest){
    
  }

}
