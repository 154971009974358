<ngx-spinner type="ball-scale-multiple"></ngx-spinner>
<!-- Banner Area -->
<div class="banner-area-three banner-bg">
    <div class="">
        <div class=" ">
            <div id="home-slider" class="carousel slide" data-bs-ride="carousel">
                <div class="carousel-inner">
                    <!-- ngfor loop using get dynamic value and ngclass for add acive class -->
                    <div class="carousel-item" *ngFor="let result of resHomeObj; let i = index" [ngClass]="{'active': i === 1}">
                        <img src={{result.banner_img}} title={{result.banner_title}} style="width: 100%;max-height: 720px;opacity: 0.5;" />
                        <div class="carousel-caption">
                            <!-- <h3>{{result.banner_title}}</h3> -->
                            <!-- <p> {{result.banner_desc}} </p> -->
                        </div>
                    </div>
                </div>
                <button class="carousel-control-prev" type="button" data-bs-target="#home-slider" data-bs-slide="prev">
                    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span class="visually-hidden">Previous</span>
                </button>
                <button class="carousel-control-next" type="button" data-bs-target="#home-slider" data-bs-slide="next">
                    <span class="carousel-control-next-icon" aria-hidden="true"></span>
                    <span class="visually-hidden">Next</span>
                </button>
            </div>
        </div>
    </div>
</div>
<br>
<br>
<br>
<!-- Banner Area End -->

<!-- Status Area -->
<div class="status-area">
    <div class="container status-bg">
        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="status-card">
                    <!-- <h3>Complete Analysis</h3> -->
                    <p style="height:58px">Striving for an equitable representation of Hindu Dharma and Indic civilization
                    </p>

                    <div class="status-bottom-1"></div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="status-card">
                    <!-- <h3>Save Your Tax</h3> -->
                    <p style="height:58px">Working with educators and the broader community
                    </p>
                    <!-- <i class='flaticon-bank'></i> -->
                    <div class="status-bottom-2"></div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 offset-lg-0 offset-md-3">
                <div class="status-card">
                    <!-- <h3>Well Approach</h3> -->
                    <p style="height:58px">Expanding cultural proficiency in classrooms across the USA
                        <div class="status-bottom-3"></div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Status Area End -->

<!-- About Area -->
<div class="about-area pt-100 pb-70" id="about">
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-6 col-md-12 pl-0">
                <div class="about-img-3">
                    <img src="{{resAboutObj?.about_img}}" alt="Images">
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="about-content about-content-max">
                    <span>About Us</span>
                    <h2>{{this.resAboutObj?.about_title}}</h2>

                    <p style="text-align: justify;">{{this.resAboutObj?.about_desc}}</p>

                    <div class="row">
                        <!-- <div class="col-lg-4 col-md-4 pr-0">
                            <div class="about-counter">
                                <i class="flaticon-medal"></i>
                                <div class="content">
                                    <h3>{{resEventStatsList?.students?.students}}</h3>
                                    <span>Students Participated</span>
                                </div>
                            </div>
                        </div> -->
<!-- 
                        <div class="col-lg-6 col-md-6 pr-0">
                            <div class="about-counter">
                                <i class="bx bx-user-circle"></i>
                                <div class="content">
                                    <h3>{{this.resEventStatsList?.teachers?.teachers | number:'1.0':'en-US' }}</h3>
                                    <h3>{{resEventStatsList?.teachers?.teachers}}</h3> 
                                    <span>Teachers Honoured</span>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-6 pr-0">
                            <div class="about-counter">
                                <i class="bx bx-group"></i>
                                <div class="content">
                                    <h3>{{this.resEventStatsList?.guest.guests | number:'1.0':'en-US'}}</h3>
                                    <h3>{{resEventStatsList?.guest?.guests}}</h3> 
                                    <span>Guests Participated</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-6 col-md-6 pr-0">
                            <div class="about-counter">
                                <i class="bx bx-buildings"></i>
                                <div class="content">
                                    <h3>80</h3>
                                     <h3>{{resEventStatsList?.teachers?.teachers}}</h3> 
                                    <span>Cities</span>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-6 pr-0">
                            <div class="about-counter">
                                <i class="bx bx-building-house"></i>
                                <div class="content">
                                    <h3>50</h3>
                                     <h3>{{resEventStatsList?.guest?.guests}}</h3> 
                                    <span>States</span>
                                </div>
                            </div>
                        </div> -->

                        <div class="col-lg-6 col-md-6 pr-0">
                            <div class="about-counter">
                                <i class="bx bx-user-circle"></i>
                                <div class="content">
                                    <!-- <h3>{{this.resEventStatsList?.teachers?.teachers | number:'1.0':'en-US' }}</h3> -->
                                    <!-- <h3>{{resEventStatsList?.teachers?.teachers}}</h3> -->
                                     <h3>2000</h3>
                                    <span>Educators</span>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-6 pr-0">
                            <div class="about-counter">
                                <i class="bx bx-group"></i>
                                <div class="content">
                                    <!-- <h3>{{this.resEventStatsList?.guest.guests | number:'1.0':'en-US'}}</h3> -->
                                    <!-- <h3>{{resEventStatsList?.guest?.guests}}</h3> -->
                                    <h3>8656</h3>
                                    <span>Participants</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-6 col-md-6 pr-0">
                            <div class="about-counter">
                                <i class="bx bx-buildings"></i>
                                <div class="content">
                                    <h3>82</h3>
                                    <!-- <h3>{{resEventStatsList?.teachers?.teachers}}</h3> -->
                                    <span>Chapters</span>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-6 pr-0">
                            <div class="about-counter">
                                <i class="bx bx-building-house"></i>
                                <div class="content">
                                    <h3>22</h3>
                                    <!-- <h3>{{resEventStatsList?.guest?.guests}}</h3> -->
                                    <span>States</span>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-6 pr-0">
                            <div class="about-counter">
                                <i class="bx bx-calendar-event"></i>
                                <div class="content">
                                    <h3>72</h3>
                                    <!-- <h3>{{resEventStatsList?.guest?.guests}}</h3> -->
                                    <span>Events</span>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>
        </div>
        <!-- <h3 style="text-align: center;">15,000+ teachers were honored from 100+ cities across 33 states since 2005</h3> -->
    </div>
</div>
<!-- About Area End -->
<!-- Services Area -->
<div class="services-area pb-70" style="background-color: #f8f9fb;">
    <div class="container">
        <div class="section-title text-center">
            <span>Our Projects</span>
            <h2>Increasing cultural proficiency in classrooms</h2>
        </div>
        <div class="row pb-20 pt-45">
            <div class="col-lg-4 col-md-6" *ngFor="let projects of resProjectList; let i = index">
                <div class="services-card services-card-bg">
                    <a routerLink="/projects/{{projects.id}}"></a>
                    <h3><a routerLink="/projects/{{projects.id}}">{{projects.project_name}}</a></h3>
                    <p style="overflow-y: hidden;max-height: 200px;text-overflow: ellipsis;"> {{projects.project_desc}}
                    </p>
                    <p><a href="projects/{{projects.id}}">Read More</a></p>
                    <a routerLink="/projects/{{projects.id}}" class="services-more"><i
                            class='flaticon-double-right-arrows-angles'></i></a>
                    <div class="services-card-bottom"></div>
                </div>
            </div>
        </div>
    </div>

    <div class="service-shape">
        <div class="shape1"><img src="assets/img/shape/shape6.png" alt="Images"></div>
        <div class="shape2"><img src="assets/img/shape/shape7.png" alt="Images"></div>
        <div class="shape3"><img src="assets/img/shape/shape8.png" alt="Images"></div>
    </div>
</div>
<!-- Services Area End -->

<!-- Video Area -->
<!-- <div class="video-area video-bg2">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-12">
                <div class="video-btn">
                    <a href="https://www.youtube.com/watch?v=07d2dXHYb94&t=11s" class="video-play-btn popup-btn"><i class="flaticon-play-button"></i></a>
                </div>
            </div>

            <div class="col-lg-8 col-md-12">
                <div class="video-content">
                    <div class="section-title">
                        <span>Intro Video</span>
                        <h2>Explore Us Watch This Full Video and Know Us</h2>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="container-fluid mr-0 pr-0">
        <div class="guidelines-area guidelines-max guidelines-bg2">
            <div class="row">
                <div class="col-lg-6 col-md-12">
                    <div class="guidelines-content">
                        <h2>A Proper Guideline Can Make Your Business Successful</h2>
                        <p>Proin magna felis, tincidunt eget lectus id, maximus ultricies eros. Aliquam eu lacus vitae nulla faucibus mollis. Proin velit mi, molestie commodo neque laoreet a.Fusce cursus iaculis elit vitae auctor. Mauris venenatis vestibulum eros, sed turpis molestie ac. Nunc convallis nisi in maximus tincidunt.</p>
                        <div class="signature"><img src="assets/img/signature.png" alt="Images"></div>
                        <div class="content">
                            <h3>Smithy Karnes</h3>
                            <span>Founder & CEO</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div> -->
<!-- Video Area End -->


<!-- Guidelines Area -->

<!-- Guidelines Area End -->

<!-- Client Area -->
<div class="client-area-two ptb-100" *ngIf="testimonialList?.length" style="background-color: white;">
    <div class="container">
        <div class="section-title text-center">
            <span>Testimonials</span>
            <h2>What teachers say about our projects</h2>
        </div>

        <div class="testimonial-slider2 owl-carousel owl-theme pt-45">
            <!--  " -->
            <div *ngFor="let data of testimonialList;   let i = index" [ngClass]="{'active': i === 1}">
                <div class="client-card" style="background-color:#f8f9fb;">

                    <p>{{data.t_desc}}</p>
                    <i class="flaticon-quote client-card-quote"></i>

                </div>
            </div>
        </div>
        <div class="testimonial-videoslider owl-carousel owl-theme pt-45">
            <!--  " -->
            <div class="client-card" style="background-color:#f8f9fb;">
                <iframe width="100%" height="auto" src="https://www.youtube.com/embed/sHfdUX_0hLI" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                <i class="flaticon-quote client-card-quote"></i>

            </div>
            <div class="client-card" style="background-color:#f8f9fb;">
                <iframe width="100%" height="auto" src="https://www.youtube.com/embed/nNs7Ld_9kio" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                <i class="flaticon-quote client-card-quote"></i>

            </div>
            <div class="client-card" style="background-color:#f8f9fb;">
                <iframe width="100%" height="auto" src="https://www.youtube.com/embed/V56wonQqTas" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                <i class="flaticon-quote client-card-quote"></i>

            </div>
            <div class="client-card" style="background-color:#f8f9fb;">
                <iframe width="100%" height="auto" src="https://www.youtube.com/embed/Xzgni7M68r0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                <i class="flaticon-quote client-card-quote"></i>

            </div>

        </div>
    </div>
</div>

<div class="client-area-two ptb-100" *ngIf="resImgObj?.images.length">

    <div class="section-title text-center">
        <span>Gallery</span>
        <h2>Discover the best things we do</h2>
    </div>

    <div class="client-area-two ptb-100">
        <div class="">
            <div class="gallery-slider2 owl-carousel owl-theme ">
                <div *ngFor="let image of resImgObj?.images; let j = index" [ngClass]="{'active': j === 1}">
                    <img src={{image.event_img}} style="max-height: 720px;opacity: 1;" />
                </div>
            </div>
        </div>
    </div>
</div>

<div class="client-area-two ptb-100" style="background-color: white;">
    <div class="container">
        <div class="row">
            <div class="section-title text-center">
                <span>Subscription</span>
                <h2>Subscribe with your email address to receive updates</h2>
            </div>
            <div class="col-lg-12 col-md-12 client-card" style="padding: 0;">
                <div style="max-width: 100%;" class="contact-form">
                    <form [formGroup]="homeForm" (submit)="submitHome()">
                        <div class="row">
                            <div class="col-lg-4 col-sm-4">
                                <div class="form-group">
                                    <input type="email" name="email" formControlName="email" id="email" class="form-control" placeholder="Email Address" required autocomplete="off">
                                </div>
                            </div>
                            <div class="col-lg-4 col-sm-4">
                                <div class="form-group">
                                    <select class="form-control" disabled>
                                        <option>-- -- --Select Country-- -- --</option>
                                        <option selected> USA </option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-lg-4 col-sm-4">
                                <div class="form-group">
                                    <ngx-recaptcha2 #captchaElem [siteKey]="siteKey" formControlName="recaptcha">
                                    </ngx-recaptcha2>
                                </div>
                            </div>
                            <div style="margin-top: 30px;" class="col-lg-12 col-md-12 text-center">
                                <button type="submit" [disabled]="!homeForm.valid" class="btn default-btn">Submit</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Client Area End -->