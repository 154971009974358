<ngx-spinner type="ball-scale-multiple"></ngx-spinner>
<div class="container">
    <div class="row">
        <div class="col-lg-12 col-md-12">
            <div style="max-width: 100%;" class="contact-form">
                <div class="section-title text-center">
                    <h2>Add Guest</h2>
                </div>

                <form [formGroup]="addGuestForm" (submit)="submitGuest()">

                    <div class="row">
                        <div class="col-lg-12 col-sm-12" style="text-align: center;">
                            <div class="form-group row">
                                <label class="col-sm-3 offset-sm-2 col-form-label">Guest Type : </label>
                                <div class="col-sm-2">
                                    <div class="form-check">
                                        <label class="form-check-label">
                                            <input type="radio" class="form-check-input" formControlName="guest_type" (change)="changeGuestType($event)" name="guest_type" value="1">
                                            Other
                                        </label>
                                    </div>
                                </div>
                                <div class="col-sm-2">
                                    <div class="form-check">
                                        <label class="form-check-label">
                                            <input type="radio" class="form-check-input" formControlName="guest_type" (change)="changeGuestType($event)" name="guest_type" value="2">
                                            Teacher
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 col-sm-6">
                            <div class="form-group">
                                <label>First Name</label>
                                <input type="text" name="name" formControlName="fname" id="fname" class="form-control"
                                    placeholder="First name" autocomplete="off">
                            </div>
                        </div>
                        <div class="col-lg-6 col-sm-6">
                            <div class="form-group">
                                <label>Last Name</label>
                                <input type="text" name="venueName" formControlName="lname" id="lname"
                                    class="form-control" placeholder="Last Name" autocomplete="off">
                            </div>
                        </div>
                        <div class="col-lg-6 col-sm-6">
                            <div class="form-group">
                                <label>E-Mail ID</label>
                                <input type="text" (blur)="isExistEmailId()" name="email" formControlName="email" id="lname" class="form-control"
                                    placeholder="Email" autocomplete="off">
                            </div>
                        </div>
                        <div class="col-lg-6 col-sm-6">
                            <div class="form-group">
                                <label>Phone No</label>
                                <input type="number" name="phone" formControlName="phone" id="lname" class="form-control"
                                    placeholder="Phone No." autocomplete="off">
                            </div>
                        </div>
                        <div class="col-lg-6 col-sm-6">
                            <div class="form-group">
                                <label>State</label>
                                <select formControlName="state_id" class="form-control">
                                    <option *ngFor="let state of stateList" [ngValue]="state.id"> {{state.state_name}} </option>
                                  </select>
                            </div>
                        </div>

                        <div class="col-lg-6 col-sm-6">
                            <div class="form-group">
                                <label>City</label>
                                <select  class="form-control" formControlName="city_id">
                                    <option *ngFor="let city of cityList" [ngValue]="city.id"> {{city.city_name}} </option>
                                  </select>
                            </div>
                        </div>
                        <div class="row" *ngIf="isTeacher">
                            <h3>Teacher Details</h3>
                            <div class="col-lg-4 col-sm-4">
                                <div class="form-group">
                                    <label for="exampleInputUsername1">School Name</label>
                                    <input type="text" class="form-control" name="school_name" formControlName="school_name"
                                        placeholder="School Name" autocomplete="off">
                                </div>
                            </div>
                            <div class="col-lg-4 col-sm-4">
                                <div class="form-group">
                                    <label for="exampleInputUsername1">School District Name</label>
                                    <input type="text" class="form-control" name="school_district" formControlName="school_district"
                                        placeholder="School District Name" autocomplete="off">
                                </div>
                            </div>
                            <div class="col-lg-4 col-sm-4">
                                <div class="form-group">
                                    <label>Title</label>
                                    <select name="grade" class="form-control" formControlName="grade">
                                        <option *ngFor="let title of titleList" [ngValue]="title.id"> {{title.title_names}}</option>
                                    </select>
                                    
                                </div>
                            </div>
                            <div class="col-lg-6 col-sm-6" style="display: none;">
                                <div class="form-group">
                                    <label for="exampleInputUsername1">Student Name</label>
                                    <input type="text" class="form-control" name="student_name" formControlName="student_name"
                                        placeholder="Student Name" autocomplete="off">
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-12 col-md-12">
                            <div class="form-group">
                                <label>Notes</label>
                                <textarea name="notes" formControlName="notes" class="form-control" rows="4"></textarea>
                            </div>
                        </div>
                        <div class="col-lg-12 col-md-12 text-center">
                            <button [disabled]="!addGuestForm.valid" type="submit"
                                class="btn default-btn">Submit</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
