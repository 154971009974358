import { Component, OnInit, ViewChild } from '@angular/core';
import { HEFService } from 'src/app/hefservice.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import {NgxCaptchaModule,ReCaptcha2Component} from 'ngx-captcha';
import { NgxSpinnerService } from "ngx-spinner";
import {Md5} from "md5-typescript";

@Component({
  selector: 'app-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.scss']
})
export class RegistrationComponent implements OnInit {
  stateList: any;
  cityList: any;
  isVolunteer: boolean = true;
  siteKey: any;

  registrationForm: FormGroup;
  @ViewChild('captchaElem') captchaElem: ReCaptcha2Component;

  constructor(private hEFService: HEFService, private fb: FormBuilder, private toastr: ToastrService,private ngxService:NgxSpinnerService) { }

  ngOnInit(): void {

    this.siteKey = '6LdbeNgeAAAAAGKoYj_MTYO-G3_0YLHw4e1Z992p'; 

    this.registrationForm = this.fb.group({
      'fname': [null, Validators.required],
      'lname': [null, Validators.required],
      'phone': [null, Validators.compose([Validators.required, Validators.pattern('[- +()0-9]+')])],
      'email': [null, Validators.compose([Validators.required, Validators.email])],
      'password': [null, Validators.required],
      'state_id': [null, Validators.required],
      'city_id': [null, Validators.required],
      'volunteer_img': [null],
      'type': [1],
      'profession': [null],
      'recaptcha': ['', Validators.required]
    });

    this.getAllState();
  }

  getAllState() {
    this.hEFService.getAllStateData().subscribe(res => {
      this.stateList = res;
      this.getByIdCity();
    }, error => {
      let errors = error;
    });
  }

  getByIdCity() {
    
    let stateId = this.registrationForm.get('state_id').value;
    if (stateId != null) {
      this.hEFService.getAllCityData(stateId).subscribe(res => {
        this.cityList = res;
      }, error => {
        let errors = error;
      });
    }
  }

  submitRegistration() {
    let formObj = this.registrationForm.value;
    let body = new URLSearchParams();
    body.set('fname', formObj['fname']);
    body.set('lname', formObj['lname']);
    body.set('phone', formObj['phone']);
    body.set('email', formObj['email']);
    body.set('password', Md5.init(formObj['password']));
    body.set('profession', formObj['profession']);
    body.set('state_id', formObj['state_id']);
    body.set('city_id', formObj['city_id']);
    body.set('volunteer_img', formObj['volunteer_img']);
    body.set('type', this.registrationForm.get('type').value);
    this.ngxService.show();
    this.hEFService.saveRegistration(body).subscribe(res => {
    let success = res;
    this.ngxService.hide();
      if (success.status) {
        
        this.registrationForm.reset();
        this.captchaElem.resetCaptcha();
        this.toastr.success('Registered Successfully');
      }
    }, error => {
      let errors = error;
      this.ngxService.hide();
    });
  }

  onFileSelect(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.registrationForm.get('volunteer_img').setValue(file);
    }
  }

  onChangeRadio(value) {
    if (value == "Supporter") {
      this.isVolunteer = false;

      let password = this.registrationForm.get('password');
      password.setValue(null);
      password.clearValidators();
      password.updateValueAndValidity();

      let profession = this.registrationForm.get('profession');
      profession.setValue(null);
      profession.clearValidators();
      profession.updateValueAndValidity();

      this.registrationForm.get('type').setValue(2);

    } else {
      this.isVolunteer = true;
      this.registrationForm.get('password').addValidators(Validators.required);
      this.registrationForm.get('profession').addValidators(Validators.required);

      this.registrationForm.updateValueAndValidity();
      this.registrationForm.get('type').setValue(1);
    }
  }

  isExistEmailId(){
    let emailIdControl = this.registrationForm.get('email');
    if(emailIdControl.valid){
      let reqObj = {};
      reqObj['email'] = emailIdControl.value;
      this.hEFService.checkEmail(reqObj).subscribe(res => {
        let response = res;
  
        if (!response.status) {
          this.toastr.error(response.message);
          this.registrationForm.controls['email'].setErrors({'incorrect': true});
        }
      }, error => {
        let errors = error;
      });
    }
   
  }

}
