<div class="container">
<h1 style="text-align: center;">My Events Stats</h1>
<div class="card m-5 p-3" style="margin:1rem !important">
  <div class="row">
    <div class="col-lg-12">
      <div class="banner-content" style="padding-top:0px">
        <div class="banner-btn">
          <a style="height: 47px;margin: 0;margin-bottom: 15px;float: right;" routerLink="/eventstats"
            class="get-btn">Add New Stats</a>
        </div>
      </div>
    </div>
  </div>
  <div class="table-responsive">
    <table style="margin-bottom: 0;" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger"
      class="table table-bordered table-striped table-hover">
      <thead style="text-align: center;">
        <tr>
          <th>Event Name</th>
          <th>Date</th>
          <th>State</th>
          <th>City</th>
          <th>Project</th>
          <th>Students</th>
          <th>Teachers</th>
          <th>Guests</th>
          <th>Coordinators</th>
          <th>Status</th>
          <th>Action</th>
        </tr>
      </thead>
      <tbody style="text-align: center;">
        <tr *ngFor="let volunteerEvent of volunteerEventList">
          <td>{{volunteerEvent.ev_detail_name }}</td>
          <td>{{volunteerEvent.event_date|date: 'dd/MM/yyyy' }}</td>
          <td>{{volunteerEvent.state_name }}</td>
          <td>{{volunteerEvent.city_name }}</td>
          <td>{{volunteerEvent.project_name }}</td>
          <td>{{volunteerEvent.students }}</td>
          <td>{{volunteerEvent.teachers }}</td>
          <td>{{volunteerEvent.guests }}</td>
          <td>{{volunteerEvent.volunteers }}</td>
          <td *ngIf="volunteerEvent.is_approved  == 1"><span class="badge" style="background-color: green;">Approved</span></td>
          <td *ngIf="volunteerEvent.is_approved  == 0"><span class="badge" style="background-color: red;">Pending</span></td>
          <td style="cursor: pointer;">
            <div>
              <button type="button" style="padding: 5px 10px 5px 10px" (click)="onDelete(volunteerEvent)"
                class="btn default-btn">Delete</button>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
</div>