import { Component, OnInit, ViewChild } from '@angular/core';
import { HEFService } from 'src/app/hefservice.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { GooglePlaceDirective } from 'ngx-google-places-autocomplete/ngx-google-places-autocomplete.directive';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: 'app-create-event',
  templateUrl: './create-event.component.html',
  styleUrls: ['./create-event.component.scss']
})
export class CreateEventComponent implements OnInit {
  projectList: any;
  stateList: any;
  cityList: any;
  userAddress: any;
  userLatitude: any;
  userLongitude: any;
  userDetails: any;
  createEventForm: FormGroup;
  isOnline: boolean = false;
  event_type:number;
  @ViewChild("placesRef") placesRef: GooglePlaceDirective;

  constructor(private hEFService: HEFService, private fb: FormBuilder, private router: Router, private toastr: ToastrService,private ngxService:NgxSpinnerService) { }

  ngOnInit(): void {
    this.event_type=0;
    this.createEventForm = this.fb.group({
      'name': [null, Validators.required],
      'date': [null, Validators.required],
      'desc': [null, Validators.required],
      'project_id': [null, Validators.required],
      'state_id': [null, Validators.required],
      'city_id': [null, Validators.required],
      'event_img': [null],
      'venue': [null, Validators.required],
      'starttime': [null, Validators.required],
      'endtime': [null, Validators.required],
      'venueName': [null],
      'lat': [null],
      'lng': [null],
      'zoom_link': [null],
    });

    this.userDetails = JSON.parse(this.hEFService.getAuthUser());

    if (this.userDetails != null && this.userDetails != "") {
      this.createEventForm.get('state_id').setValue(this.userDetails.data.state_id);
      this.createEventForm.get('state_id').disable();
      this.getByIdCity(this.createEventForm.get('state_id').value);
    }

    this.getAllProject();
    this.getAllState();
  }

  getAllProject() {
    this.hEFService.getAllProjectData().subscribe(res => {
      this.projectList = res;
    }, error => {
      let errors = error;
    });
  }

  getAllState() {
    this.hEFService.getAllStateData().subscribe(res => {
      this.stateList = res;
    }, error => {
      let errors = error;
    });
  }

  getByIdCity(stateId) {
    if (stateId != null) {
      this.hEFService.getAllCityData(stateId).subscribe(res => {
        this.cityList = res;
        this.createEventForm.get('city_id').setValue(this.userDetails.data.city_id);
      }, error => {
        let errors = error;
      });
    }
  }

  submitEvent() {
    let formObj = this.createEventForm.value;
    let body = new URLSearchParams();
    body.set('name', formObj['name']);
    body.set('event_img', formObj['event_img']);
    body.set('date', formObj['date']);
    body.set('desc', formObj['desc']);
    body.set('project_id', formObj['project_id']);
    body.set('state_id', this.createEventForm.get('state_id').value);
    body.set('city_id', formObj['city_id']);
    body.set('starttime', formObj['starttime']);
    body.set('endtime', formObj['endtime']);
    body.set('venue', formObj['venue']);
    body.set('vname', formObj['venueName']);
    body.set('lat', formObj['lat']);
    body.set('lng', formObj['lng']);
    body.set('zoom_link', formObj['zoom_link']);
    body.set('event_type', this.event_type.toString());

    this.hEFService.saveEvent(body).subscribe(res => {
      let success = res;
      if (success.status) {
        this.toastr.success(success.message);
        this.router.navigate(['/event/']);
      }

    }, error => {
      let errors = error;
    });

  }

  handleAddressChange(address: any) {
    this.createEventForm.get('venue').setValue(address.formatted_address);
    this.createEventForm.get('lat').setValue(address.geometry.location.lat());
    this.createEventForm.get('lng').setValue(address.geometry.location.lng());
  }

  onFileSelect(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.createEventForm.get('event_img').setValue(file);
    }
  }

  onChangeRadio(value){
    if(value == "online"){
      this.isOnline = true;
      this.event_type=1;
    }else{
      this.isOnline = false;
      this.event_type=0;
    }
  }

}
