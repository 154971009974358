import { Component, OnInit, ViewChild } from '@angular/core';
import { HEFService } from 'src/app/hefservice.service';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { GooglePlaceDirective } from 'ngx-google-places-autocomplete/ngx-google-places-autocomplete.directive';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-reset-pass',
  templateUrl: './reset-pass.component.html',
  styleUrls: ['./reset-pass.component.scss']
})
export class ResetPassComponent implements OnInit {
  resetPassForm: FormGroup;

  constructor(private hEFService: HEFService, private fb: FormBuilder, private router: Router, private toastr: ToastrService) { }

  ngOnInit(): void {
    this.resetPassForm = this.fb.group({
      'email': [null, Validators.required]
    });
  }

  submitChangePass() {
    let formObj = this.resetPassForm.value;
  
    this.hEFService.resetpassword(formObj).subscribe(res => {
      let success = res;
      if (success.status) {
        this.toastr.success(success.message);
        this.router.navigate(['/sign-in'])
      }
      else{
        this.toastr.error(success.message);
      }

    }, error => {
      let errors = error;
    });
  }

}
