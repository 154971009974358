<ngx-spinner type="ball-scale-multiple"></ngx-spinner>
<div class="container">
    <div class="row">
        <div class="col-lg-12 col-md-12">
            <div style="max-width: 100%;" class="contact-form">
                <div class="section-title text-center">
                    <h2>Invitation for Event</h2>
                </div>

                <form [formGroup]="commonInvitationForm">
                    <div class="row">
                        <div class="col-lg-6 col-md-6 offset-lg-3 offset-md-3" style="text-align: center;">

                            <h3>Event Details </h3>
                            <div class="tab project-tab text-center">
                                <div class="tab_content current active pt-45">
                                    <div class="tabs_item current">
                                        <div class="project-tab-item">
                                            <div class="row">
                                                <div class="col-lg-12 col-md-12">
                                                    <div class="blog-card">
                                                        <a><img src="{{commonInvitationDetail?.event.ev_detail_img}}"
                                                                alt="Images"></a>
                                                        <div class="content">
                                                            <h3>{{commonInvitationDetail?.event.ev_detail_name}}</h3>
                                                            <ul>
                                                                <li>Date :
                                                                    <span>{{commonInvitationDetail?.event.ev_detail_date
                                                                        | date:'MM/dd/yyyy'}}</span>
                                                                </li><br>
                                                                <li>Time :
                                                                    <span>{{commonInvitationDetail?.event.ev_detail_time}}
                                                                        to
                                                                        {{commonInvitationDetail?.event.ev_detail_etime}}</span>
                                                                </li><br>
                                                                <li>Venue :
                                                                    <span>{{commonInvitationDetail?.event.ev_detail_venue
                                                                        }}</span></li>
                                                            </ul>
                                                            <hr>
                                                            <p>{{commonInvitationDetail?.event.ev_detail_desc}}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="form-group row">
                        <label class="col-sm-1 offset-sm-4 col-form-label">Select : </label>
                        <div class="col-sm-1">
                            <div class="form-check">
                                <label class="form-check-label">
                                    <input type="radio" class="form-check-input" (change)="detailstype($event)"  name="detailtype" value="email">
                                    Email
                                </label>
                            </div>
                        </div>
                        <div class="col-sm-1">
                            <div class="form-check">
                                <label class="form-check-label">
                                    <input type="radio" class="form-check-input" (change)="detailstype($event)"  name="detailtype" value="phone">
                                    Phone
                                </label>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-3 col-sm-12">
                            <div class="form-group">
                                <label>First Name</label>
                                <input type="name" name="guest_fname" formControlName="guest_fname" id="guest_fname"
                                    class="form-control" placeholder="First Name" required>
                            </div>
                        </div>
                        <div class="col-lg-3 col-sm-12">
                            <div class="form-group">
                                <label>Last Name</label>
                                <input type="name" name="guest_lname" formControlName="guest_lname" id="guest_lname"
                                    class="form-control" placeholder="Last Name" required>
                            </div>
                        </div>
                        
                        <div class="col-lg-3 col-sm-12" *ngIf="isEmailSelected">
                            <div class="form-group">
                                <label>Email</label>
                                <input type="email" name="guest_email" formControlName="guest_email" id="guest_email"
                                    class="form-control" placeholder="Email" >
                            </div>
                        </div>
                        <div class="col-lg-3 col-sm-12" *ngIf="isPhoneSelected">
                            <div class="form-group">
                                <label>Phone</label>
                                <input type="phone" name="guest_phone" formControlName="guest_phone" id="guest_phone"
                                    class="form-control" placeholder="Phone No.">
                            </div>
                        </div>
                        
                        <div class="col-lg-3 col-sm-12">
                            <div class="form-group">
                                <ngx-recaptcha2 #captchaElem [siteKey]="siteKey" formControlName="recaptcha">
                                </ngx-recaptcha2>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-lg-12 col-md-12 text-center">
                            <div class="row">
                                <div class="col-lg-12 col-md-12" style="margin-top: 10px;text-align: center;">
                                    <button [disabled]="!commonInvitationForm.valid" style="background-color: green;" (click)="onSubmit(1)" type="submit"
                                        class=" col-lg-2 col-md-2 btn default-btn bttn">Accept</button>
                                    <button [disabled]="!commonInvitationForm.valid" style="background-color: blue;" (click)="onSubmit(2)" type="submit"
                                        class="col-lg-2 col-md-2 btn default-btn bttn">Maybe</button>
                                    <button [disabled]="!commonInvitationForm.valid" style="background-color: red;" (click)="onSubmit(3)" type="submit"
                                        class="col-lg-3 col-md-3 btn default-btn">Decline</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
<style>
    @media(max-width:550px) {
        .bttn {
            margin-right: 0px !important;
            margin-bottom: 10px !important;
        }
    }

    @media(min-width:550px) {
        .bttn {
            margin-right: 20px !important;
            margin-bottom: 0px !important;
        }
    }
</style>